import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Container, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Alert, TablePagination, InputBase, styled, Box } from '@mui/material';
import SimpleBottomNavigation from './SimpleBottomNavigation';
import api from './Interceptor';
import Cookies from 'js-cookie';

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '5px',
  fontSize: '16px',
  padding: '5px 10px',
  ...(variant === 'success' && {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  }),
  ...(variant === 'danger' && {
    backgroundColor: '#0056b3',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#003d7a',
    },
  }),
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

function Pool() {
  const [selectedPool, setSelectedPool] = useState("global1");
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchTeamData(selectedPool);
  }, [selectedPool]);

  const fetchTeamData = async (pool) => {
    setLoading(true);
    setError(null);

    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error("No authentication token found");
      }

      const decodedToken = jwtDecode(token);
      const userId = decodedToken.sub;
debugger
      const response = await api.get(`/GloblePool/${pool}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
debugger
      setTeamData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePoolClick = (pool) => {
    setSelectedPool(pool);
  };

  const filteredData = teamData.filter(item => {
    const uid1 = item.sN1_UID1 ? item.sN1_UID1.toString() : '';  // Safely convert to string if not undefined
    const position = item.current_Position ? item.current_Position.toLowerCase() : '';  // Safely convert to lowercase
  
    return uid1.includes(searchInput.toLowerCase()) || position.includes(searchInput.toLowerCase());
  });
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAddToWallet = async (uid, balance) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error("No authentication token found");
      }
  
      // Make the POST request to the API
      const response = await fetch(`http://localhost:5000/api/GloblePool/AddToWallet/${uid}`, {
        method: 'POST',  // Set method to POST
        headers: {
          'Content-Type': 'application/json',  // Set the content type
          Authorization: `Bearer ${token}`,  // Include the authorization token
        },
        body: JSON.stringify(balance),  // Send the transferAmount as JSON in the request body
      });
  
      if (response.ok) {
        const data = await response.json();  // Parse the response as JSON
        console.log('Success:', data);
        fetchTeamData();  // Handle the success response here
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message);  // Handle error if API call fails
      }
    } catch (error) {
      console.error('Error:', error);  // Handle error during the request
    }
  };
  
  
  
  
  const handleGoToNextPool = (uid) => {
    
  
    // Make the API call to move the user to the next global pool
    fetch(`http://localhost:5000/api/GloblePool/GoToNextGlobal/${uid}`, {
      method: 'GET', // HTTP method
      headers: {
        'Content-Type': 'application/json', // Set content type if needed
      }
    })
      .then(response => {
        if (response.ok) {
          // Handle success
          return response.json(); // Or just return a success message
        }
        throw new Error('Error moving user to next pool');
      })
      .then(data => {
        console.log('User moved to next global pool:', data);
      })
      .catch(error => {
        console.error('API call failed:', error);
      });
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container
      sx={{
        backgroundColor: '#f4f4f9',
        minHeight: '100vh',
        paddingY: 4,
        marginTop: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{ color: "#0056b3", mb: { xs: 2, sm: 0 }, textAlign: { xs: 'center', sm: 'left' } }}
      >
        Global Pools
      </Typography>

      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'flex-start' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          width: '100%',
          mb: 4,
        }}
      >
        <StyledInput
          placeholder="Search by ID or Position"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          sx={{
            marginBottom: { xs: 2, sm: 0 },
            marginRight: { sm: 2 },
            width: { xs: '100%', sm: '300px' },
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            justifyContent: { xs: 'center', sm: 'flex-end' },
            width: '100%',
          }}
        >
          {["globlePool1", "globlePool2", "globlePool3", "globlePool4", "globlePool5"].map(pool => (
            <StyledButton
              key={pool}
              variant={selectedPool === pool ? 'success' : 'danger'}
              onClick={() => handlePoolClick(pool)}
            >
              {`Global Pool ${pool.slice(-1)}`}
            </StyledButton>
          ))}
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Serial No.</StyledTableCell>
              <StyledTableCell>Member ID</StyledTableCell>
              <StyledTableCell>Balance</StyledTableCell>
              <StyledTableCell>Total Members</StyledTableCell>
              <StyledTableCell>Add to Wallet</StyledTableCell>
              <StyledTableCell>GoTo Next Pool</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((member) => { 
const totalMembersFieldMap = {
  "globlePool1": member.gP1_T_M,
  "globlePool2": member.gP2_T_M,
  "globlePool3": member.gP3_T_M,
  "globlePool4": member.gP4_T_M,
  "globlePool5": member.gP5_T_M,
};

const totalMembers = totalMembersFieldMap[selectedPool]; // Get total members based on selected pool
  let displayRows = 0;
  let totalMembersToShow = [];
debugger
  if (totalMembers <= 4) {
    displayRows = 1; // Show only the first row
    totalMembersToShow = [totalMembers]; // Show total members as is
  } else if (totalMembers <= 20) {
    displayRows = 2; // Show first and second rows
    totalMembersToShow = [4, totalMembers - 4]; // Show 4 in the first row and remaining in the second
  } else if (totalMembers <= 84) {
    displayRows = 3; // Show all three rows
    totalMembersToShow = [4, 16, totalMembers - 20]; // Show 4, 16, and remaining in the third row
  }

  // Create an array to hold the rows to be displayed
  const rowsToDisplay = Array.from({ length: displayRows }, (_, i) => {
    let uid, gPl1, balance;

    // Determine which properties to use based on the selected pool
    switch (selectedPool) {
 
      case "globlePool1":
        uid = member.sN1_UID1;
        gPl1 = member.sN1_GPl1;
        balance = member.gP1Balance; // Adjust if necessary
        break;
      case "globlePool2":
        uid = member.sN2_UID2;
        gPl1 = member.SN2_GPl2;
        balance = member.gP2Balance; // Adjust if necessary
        break;
      case "globlePool3":
        uid = member.sN3_UID3;
        gPl1 = member.SN3_GPl3;
        balance = member.gP3Balance; // Adjust if necessary
        break;
      case "globlePool4":
        uid = member.sN4_UID4;
        gPl1 = member.SN4_GPl4;
        balance = member.gP4Balance; // Adjust if necessary
        break;
      case "globlePool5":
        uid = member.sN5_UID5;
        gPl1 = member.SN5_GPl5;
        balance = member.gP5Balance; // Adjust if necessary
        break;
      default:
        uid = '';
        gPl1 = '';
        balance = 0;
    }
    
    return (
      <StyledTableRow key={`${gPl1}-${i}`}>
        <TableCell>{i + 1}</TableCell> {/* Serial number 1, 2, 3 */}
        <TableCell>{uid}</TableCell>
        <TableCell>{balance}</TableCell>
        <TableCell>{totalMembersToShow[i]}</TableCell> {/* Adjust Total Members display */}
        <TableCell>
          {i === displayRows - 1 && ( // Show "Add to Wallet" button only in the last visible row
            <StyledButton
              variant="success"
              onClick={() => handleAddToWallet(uid, balance)} 
            >
              Add to Wallet
            </StyledButton>
          )}
        </TableCell>
        <TableCell>
          {i === displayRows - 1 && ( // Show "GoTo Next Pool" button only in the last row
            <StyledButton
              variant="success"
              onClick={() => handleGoToNextPool(uid)}
            >
              GoTo Next Pool
            </StyledButton>
          )}
        </TableCell>
      </StyledTableRow>
    );
  });

  return rowsToDisplay;
})}
        </TableBody>
        </Table>
      </TableContainer>

      <StyledPagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
}

export default Pool;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, SwipeableDrawer, Box, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StarIcon from '@mui/icons-material/Star';
import LogoutIcon from '@mui/icons-material/Logout';
import {jwtDecode} from 'jwt-decode';
function Header() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const updateUserRole = () => {
    const token = sessionStorage.getItem('authToken');  // Get token from cookies
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decoding the JWT token
        const { role } = decodedToken;
        setUserRole(role);
      } catch (error) {
     
        setUserRole(null); // In case of decoding error
      }
    } else {
      setUserRole(null);
    }
  };

  useEffect(() => {
    updateUserRole();
  }, []);

  const handleLogout = () => {
    debugger
    sessionStorage.removeItem('authToken'); // Remove token from cookies
    setUserRole(null);  // Clear the user role to hide the menu
    navigate('/Login'); // Redirect to Login page
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <Box
      sx={{ 
        width: 250,
        bgcolor: 'inherit'
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/Index">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Index" />
          </ListItemButton>
        </ListItem>
     
        {userRole === 'Admin' && (
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/request">
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Request" />
            </ListItemButton>
          </ListItem>
        )}
         
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/pool">
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="GlobalPool" />
            </ListItemButton>
          </ListItem>
  
        {userRole === 'Admin' && (
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/history">
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="History" />
            </ListItemButton>
          </ListItem>
        )}
            {userRole === 'Admin' && (
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/IsActive">
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="Activate" />
          </ListItemButton>
        </ListItem>
          )}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed"  sx={{ backgroundColor: 'rgba(0, 123, 255, 0.8)', backdropFilter: 'blur(10px)' }}>
      <Toolbar>
      <Typography 
  variant="h6" 
  sx={{ 
    flexGrow: 1, 
    textDecoration: 'none', // Removes underline from the link
    color: 'inherit', // Inherits color from parent, or you can set a specific color
    fontWeight: 'bold', // Makes the text bold
    letterSpacing: '0.5px' // Adds a little spacing between letters for a cleaner look
  }} 
  component={Link} 
  to="/"
>
  MOM
</Typography>
<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
      <Typography 
        variant="h6" 
        sx={{ 
          textDecoration: 'none',
          color: 'inherit',
          fontWeight: 'bold',
          letterSpacing: '0.5px',
        }} 
        component={Link} 
        to="/contactUs"
      >
        ContactUS
      </Typography>
    </div>
        
        {(userRole === 'Admin' || userRole === 'User'|| userRole === 'Dev') ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Button color="inherit" onClick={() => navigate('/Login')}>
            Login
          </Button>
          
        )}

        <SwipeableDrawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          PaperProps={{
            sx: {
              bgcolor: '#d8e7f5',
              color: '#000000'
            }
          }}
        >
          {drawerList()}
        </SwipeableDrawer>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

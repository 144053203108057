import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import UserRegister from './component/UserRegister';
import Login from './component/Login';
import Index from './component/Index';
import IsActive from './component/IsActive';
import Team from './component/Team';
import Profile from './component/profile'; // Ensure consistency in case
import Wallet from './component/Wallet';
import Home from './component/Home';
import Header from './component/Header';
import UserTransactions from './component/UserTransactions';
import Request from './component/Request';
import History from './component/History';
import SimpleBottomNavigation from './component/SimpleBottomNavigation';
import PrivateRoute from './component/PrivateRoute'; // Import the PrivateRoute component
import UserHistory from './component/userHistory';
import ContactUs from './component/ContactUs';
import Pool from './component/Pool';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/userRegister" element={<UserRegister />} />
        <Route path="/login" element={<Login />} />
        <Route path="/index" element={<Index />}  />
        <Route path="/pool" element={<Pool />}  />
        <Route path="/isActive" element={<PrivateRoute element={<IsActive />} requiredRole="Admin"/>}/>
        <Route path="/team" element={<Team />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/userHistory/:userId" element={<UserHistory />} />
        <Route
          path="/profile"
          element={<Profile />}
        />
        <Route
          path="/wallet"
           element={<Wallet />} 
        />
        <Route
          path="/transactions/:userId"
          element={<PrivateRoute element={<UserTransactions />} />}
        />
        <Route
          path="/request"
          element={<PrivateRoute element={<Request />} requiredRole="Admin"/>}
        />
   <Route
          path="/history"
          element={<PrivateRoute element={<History />} requiredRole="Admin" />} // Only admins can access
        />
      </Routes>
      <NavigationWrapper />
    </Router>
  );
}

function NavigationWrapper() {
  const location = useLocation();

  // List of paths where SimpleBottomNavigation should not be displayed
  const noNavPaths = ['/', '/userRegister', '/login'];

  // Check if the current path is in the list of paths to hide the navigation
  const shouldHideNav = noNavPaths.includes(location.pathname);

  return (
    !shouldHideNav && <SimpleBottomNavigation />
  );
}

export default App;
